// Mixins
@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flexBox($justify, $align) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
}

// Import
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// Variables
// Main Colors
$main-color: #0f1922;
$main-color-alt: #e31b6d;
$paragraph-color: #ffffff80;
$paragraph-color-alt: #ffffffcc;
$glass-color: #ffffff33;
$header-background: #1c2733;
$alt-color: #2662d9;
$alt-color2: #273039;
$main-gradient: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);

// Other Variables
$main-transition: 0.4s;
$main-padding: 50px 0;
$starsColor: #fff;

// Global Rules
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  user-select: none;
}

body {
  overflow-x: hidden;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 1024px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1439px) {
  .container {
    width: 1170px;
  }
}

.container {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

a {
  text-decoration: none;
  color: unset;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 0;
}
