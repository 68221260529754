@use "../../index.scss" as *;

.portfolio {
  font-family: "Cairo", sans-serif;
  width: 100vw;
  position: relative;
  z-index: 5;
  padding: 100px 0;
  background-color: $main-color;
  .container {
    .mainTitle {
      width: fit-content;
      text-transform: uppercase;
      color: $alt-color;
      position: relative;
      font-size: 1rem;
      letter-spacing: 1px;
      left: 50%;
      transform: translateX(-50%);
      margin: -20px 0 50px;
    }
    .mainTitle::after {
      content: "";
      width: 50%;
      height: 5px;
      position: absolute;
      border-radius: 5px;
      background-color: $alt-color;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0px;
    }
    .swiper-wrapper {
      width: fit-content;
      background-color: transparent;
      position: relative;
      z-index: 5;
      .swiper-slide {
        position: relative;
        z-index: 5;
        opacity: 1;
        text-align: center;
        background: rgba(255, 255, 255, 0.1);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(1px);
        text-transform: capitalize;
        @include flexCenter();
        overflow: hidden;
        .image {
          display: flex;
          img {
            max-width: 100%;
          }
        }
        .topTie {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          height: 50%;
          padding: 15px 25px;
          position: absolute;
          bottom: -50%;
          background: rgba(15, 25, 34, 0.5);
          backdrop-filter: blur(4px);
          transition: $main-transition;
          @include flexCenter();
          color: #fff;
          font-size: 0.8rem;
          text-align: center;
          z-index: 5000;
          h4 {
            color: rgba($color: #fff, $alpha: 0.6);
          }
        }
        .bottomTie {
          width: 100%;
          height: 50%;
          position: absolute;
          top: -50%;
          background: rgba(15, 25, 34, 0.5);
          backdrop-filter: blur(4px);
          @include flexCenter();
          transition: $main-transition;
          z-index: 5000;
          .projectsBotton {
            padding: 15px 25px;
            text-transform: capitalize;
            border: 2px solid white;
            outline: none;
            font-weight: bold;
            position: relative;
            cursor: pointer;
            transition: $main-transition;
            background-color: white;
          }
          .projectsBotton:hover {
            color: white;
            background-color: $main-color;
          }
        }
      }
      .swiper-slide-active:hover .topTie {
        bottom: 50%;
      }
      .swiper-slide-active:hover .bottomTie {
        top: 50%;
      }
    }
  }
}

.Modal {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  border: none;
  background-color: $alt-color2;
}

.modalOverLay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 4445;
  background-color: rgba(15, 25, 34, 0.5);
  backdrop-filter: blur(4px);
}

.modal {
  font-family: "Cairo", sans-serif;
  padding: 0 25px;
  .closeIcon {
    font-size: 2rem;
    color: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: -8px;
    left: 0px;
    transition: $main-transition;
    cursor: pointer;
  }
  .closeIcon:hover {
    color: rgba(255, 255, 255, 0.3);
  }
  .topHeader {
    margin: 20px auto;
    @include flexBox(space-between, center);
    .modalTitle {
      text-transform: capitalize;
      width: fit-content;
      color: $alt-color;
      position: relative;
      letter-spacing: 1px;
      text-align: center;
    }
    .modalTitle::after {
      content: "";
      width: 50%;
      height: 5px;
      position: absolute;
      border-radius: 5px;
      background-color: $alt-color;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0px;
    }
    .icons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }
    .icon {
      font-size: 2rem;
      color: $paragraph-color;
      position: relative;
      top: 7px;
      margin-right: 5px;
      transition: $main-transition;
      cursor: pointer;
    }
    .icon:hover {
      color: #171515;
    }
    .disabled {
      color: #f00;
      cursor: not-allowed;
      opacity: 0.6;
    }
    .disabled:hover {
      color: #f00;
    }
  }
  .liveSite {
    width: 100%;
    height: 100vh;
    background-color: white;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin: 25px 0;
  }
  .descriptionTitle {
    text-transform: capitalize;
    width: fit-content;
    color: $alt-color;
    position: relative;
    letter-spacing: 1px;
  }
  .description {
    color: $paragraph-color;
    line-height: 2;
    word-spacing: 1.5px;
    letter-spacing: 1px;
    margin-left: 25px;
    margin-bottom: 25px;
  }
  .technologies {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 1rem 0;
    .tech {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      padding: 1rem;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(4px);
      transition: all 0.3s ease;
      min-width: 100px;
      &:hover {
        transform: translateY(-5px);
        background: rgba(255, 255, 255, 0.2);
      }
      svg {
        font-size: 2rem;
        color: $alt-color;
      }
      p {
        font-size: 0.9rem;
        color: white;
        margin: 0;
        text-transform: capitalize;
      }
    }
  }
}

@media (max-width: 566px) {
  .modal {
    padding: 0;
    .liveSite {
      width: 100%;
    }
  }
  .Modal {
    left: 50%;
    transform: translateX(-50%);
    width: 98%;
  }
}
