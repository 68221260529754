@use "../../index.scss" as *;

.body {
  font-family: "Raleway", sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background: $main-gradient;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  #stars {
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: 1589px 771px $starsColor, 363px 759px $starsColor,
      1535px 66px $starsColor, 321px 540px $starsColor, 1096px 99px $starsColor,
      1112px 848px $starsColor, 1475px 1809px $starsColor,
      1570px 1059px $starsColor, 1100px 119px $starsColor,
      1193px 1745px $starsColor, 1262px 1036px $starsColor,
      1711px 102px $starsColor, 1872px 69px $starsColor,
      1832px 1794px $starsColor, 65px 1306px $starsColor,
      496px 1659px $starsColor, 1997px 526px $starsColor,
      540px 1773px $starsColor, 41px 700px $starsColor,
      1924px 1817px $starsColor, 1424px 765px $starsColor,
      1125px 1213px $starsColor, 291px 243px $starsColor,
      1700px 40px $starsColor, 90px 526px $starsColor, 468px 1181px $starsColor,
      176px 1172px $starsColor, 550px 111px $starsColor,
      1746px 958px $starsColor, 829px 1898px $starsColor,
      1197px 932px $starsColor, 1147px 950px $starsColor,
      397px 1236px $starsColor, 1684px 1379px $starsColor,
      1362px 1358px $starsColor, 1457px 202px $starsColor,
      1467px 1894px $starsColor, 1950px 94px $starsColor,
      1567px 1288px $starsColor, 990px 1266px $starsColor,
      1866px 25px $starsColor, 1099px 1691px $starsColor,
      876px 660px $starsColor, 325px 1645px $starsColor,
      1578px 1612px $starsColor, 1269px 1289px $starsColor,
      1771px 367px $starsColor, 498px 746px $starsColor,
      1260px 836px $starsColor, 1002px 264px $starsColor,
      1731px 1234px $starsColor, 1389px 1899px $starsColor,
      1447px 149px $starsColor, 713px 250px $starsColor, 579px 497px $starsColor,
      1853px 383px $starsColor, 1816px 1558px $starsColor,
      1050px 468px $starsColor, 760px 1492px $starsColor,
      1322px 298px $starsColor, 1198px 1619px $starsColor,
      31px 167px $starsColor, 580px 880px $starsColor, 140px 1810px $starsColor,
      926px 1199px $starsColor, 1462px 1206px $starsColor,
      260px 1843px $starsColor, 411px 415px $starsColor,
      1583px 486px $starsColor, 727px 836px $starsColor,
      309px 1831px $starsColor, 1521px 399px $starsColor,
      364px 164px $starsColor, 309px 1961px $starsColor, 217px 749px $starsColor,
      1395px 641px $starsColor, 1678px 545px $starsColor,
      757px 1759px $starsColor, 1165px 1716px $starsColor,
      1603px 1013px $starsColor, 977px 765px $starsColor,
      581px 765px $starsColor, 1419px 1880px $starsColor,
      426px 557px $starsColor, 7px 885px $starsColor, 699px 1934px $starsColor,
      40px 1899px $starsColor, 1509px 696px $starsColor, 131px 442px $starsColor,
      505px 840px $starsColor, 536px 513px $starsColor,
      1942px 1751px $starsColor, 1949px 915px $starsColor,
      1986px 391px $starsColor, 1875px 1303px $starsColor,
      363px 471px $starsColor, 847px 1250px $starsColor,
      1180px 1548px $starsColor, 427px 379px $starsColor,
      714px 892px $starsColor, 1624px 1754px $starsColor,
      1268px 328px $starsColor, 1651px 1562px $starsColor,
      1823px 1799px $starsColor, 461px 1294px $starsColor,
      1656px 1455px $starsColor, 256px 1903px $starsColor,
      547px 189px $starsColor, 1043px 133px $starsColor,
      740px 1288px $starsColor, 1552px 1504px $starsColor,
      558px 823px $starsColor, 1660px 1735px $starsColor,
      1506px 342px $starsColor, 165px 1009px $starsColor,
      1266px 260px $starsColor, 163px 1646px $starsColor,
      772px 1376px $starsColor, 1026px 672px $starsColor,
      608px 728px $starsColor, 420px 1311px $starsColor,
      1065px 1956px $starsColor, 1156px 154px $starsColor,
      1014px 442px $starsColor, 526px 98px $starsColor, 305px 854px $starsColor,
      1104px 44px $starsColor, 169px 1861px $starsColor,
      1556px 465px $starsColor, 493px 1327px $starsColor,
      965px 1094px $starsColor, 62px 1728px $starsColor, 123px 285px $starsColor,
      791px 1204px $starsColor, 1226px 620px $starsColor,
      848px 696px $starsColor, 1500px 501px $starsColor,
      1834px 1034px $starsColor, 1479px 381px $starsColor,
      978px 103px $starsColor, 1082px 166px $starsColor,
      1628px 399px $starsColor, 776px 628px $starsColor,
      1077px 1058px $starsColor, 1027px 136px $starsColor,
      172px 301px $starsColor, 1108px 1490px $starsColor,
      1682px 1917px $starsColor, 579px 293px $starsColor,
      848px 1353px $starsColor, 817px 780px $starsColor,
      1874px 718px $starsColor, 117px 1123px $starsColor,
      1491px 886px $starsColor, 1593px 195px $starsColor,
      308px 1583px $starsColor, 1491px 1781px $starsColor,
      990px 1073px $starsColor, 958px 57px $starsColor, 72px 1093px $starsColor,
      1562px 36px $starsColor, 129px 1640px $starsColor,
      1727px 1647px $starsColor, 1842px 364px $starsColor,
      1919px 1206px $starsColor, 1817px 1613px $starsColor,
      1270px 674px $starsColor, 836px 892px $starsColor,
      1733px 267px $starsColor, 731px 1663px $starsColor,
      1483px 1039px $starsColor, 467px 109px $starsColor,
      882px 1827px $starsColor, 161px 19px $starsColor, 260px 997px $starsColor,
      130px 1652px $starsColor, 1916px 368px $starsColor,
      286px 1044px $starsColor, 1424px 617px $starsColor,
      1302px 1140px $starsColor, 16px 493px $starsColor,
      1509px 1341px $starsColor, 433px 959px $starsColor,
      297px 166px $starsColor, 113px 310px $starsColor, 137px 1113px $starsColor,
      778px 994px $starsColor, 1786px 674px $starsColor,
      417px 1765px $starsColor, 1036px 1927px $starsColor,
      1773px 74px $starsColor, 1877px 1629px $starsColor,
      1229px 649px $starsColor, 1939px 1209px $starsColor,
      505px 227px $starsColor, 403px 1505px $starsColor,
      735px 1953px $starsColor, 1059px 1463px $starsColor,
      396px 604px $starsColor, 827px 1193px $starsColor,
      1812px 1359px $starsColor, 1604px 328px $starsColor,
      122px 668px $starsColor, 417px 1400px $starsColor,
      1251px 433px $starsColor, 1484px 573px $starsColor,
      1048px 1870px $starsColor, 1450px 241px $starsColor,
      943px 1418px $starsColor, 465px 1378px $starsColor,
      1111px 1396px $starsColor, 1225px 1639px $starsColor,
      583px 1142px $starsColor, 722px 1611px $starsColor,
      678px 979px $starsColor, 1993px 432px $starsColor, 87px 106px $starsColor,
      1482px 1736px $starsColor, 638px 954px $starsColor,
      718px 1255px $starsColor, 492px 1220px $starsColor,
      1407px 28px $starsColor, 1063px 208px $starsColor, 401px 33px $starsColor,
      425px 984px $starsColor, 1139px 933px $starsColor, 194px 547px $starsColor,
      196px 889px $starsColor, 830px 1618px $starsColor, 692px 397px $starsColor,
      11px 174px $starsColor, 551px 1533px $starsColor,
      1385px 1578px $starsColor, 1932px 385px $starsColor,
      1065px 1445px $starsColor, 1574px 398px $starsColor,
      1517px 769px $starsColor, 1764px 501px $starsColor,
      1141px 1880px $starsColor, 54px 39px $starsColor, 940px 1363px $starsColor,
      777px 1645px $starsColor, 244px 31px $starsColor, 1503px 880px $starsColor,
      27px 681px $starsColor, 796px 1725px $starsColor, 429px 801px $starsColor,
      693px 862px $starsColor, 1825px 1936px $starsColor,
      425px 471px $starsColor, 566px 649px $starsColor, 641px 21px $starsColor,
      1200px 1044px $starsColor, 1977px 1161px $starsColor,
      1359px 1809px $starsColor, 1641px 986px $starsColor,
      760px 1080px $starsColor, 1822px 1644px $starsColor,
      904px 443px $starsColor, 1500px 1620px $starsColor,
      1654px 1215px $starsColor, 998px 1598px $starsColor,
      1187px 395px $starsColor, 1680px 298px $starsColor,
      1420px 1411px $starsColor, 374px 374px $starsColor,
      878px 1812px $starsColor, 420px 1018px $starsColor,
      222px 1659px $starsColor, 1983px 313px $starsColor,
      1464px 1908px $starsColor, 1873px 1221px $starsColor,
      1801px 1630px $starsColor, 1497px 189px $starsColor,
      1016px 649px $starsColor, 237px 1295px $starsColor,
      977px 780px $starsColor, 1355px 651px $starsColor, 791px 696px $starsColor,
      1666px 566px $starsColor, 370px 1233px $starsColor,
      671px 1332px $starsColor, 1517px 1043px $starsColor,
      324px 541px $starsColor, 707px 1862px $starsColor,
      771px 1212px $starsColor, 1243px 1705px $starsColor,
      604px 1123px $starsColor, 1773px 1979px $starsColor,
      1509px 340px $starsColor, 1869px 143px $starsColor,
      725px 604px $starsColor, 1650px 946px $starsColor,
      1625px 246px $starsColor, 402px 1031px $starsColor,
      1231px 288px $starsColor, 1510px 504px $starsColor,
      1051px 1247px $starsColor, 277px 164px $starsColor,
      1149px 1314px $starsColor, 1483px 527px $starsColor,
      164px 1007px $starsColor, 217px 1036px $starsColor,
      1512px 1288px $starsColor, 607px 1423px $starsColor,
      1019px 296px $starsColor, 192px 1409px $starsColor,
      259px 648px $starsColor, 501px 1720px $starsColor,
      1868px 1308px $starsColor, 430px 1976px $starsColor,
      918px 1676px $starsColor, 925px 1672px $starsColor,
      242px 1315px $starsColor, 1485px 1922px $starsColor,
      1960px 103px $starsColor, 778px 1808px $starsColor,
      43px 1857px $starsColor, 1382px 283px $starsColor,
      1040px 1452px $starsColor, 497px 1019px $starsColor,
      287px 668px $starsColor, 396px 875px $starsColor, 1956px 971px $starsColor,
      1456px 1219px $starsColor, 1047px 803px $starsColor,
      1795px 72px $starsColor, 1465px 333px $starsColor,
      948px 1899px $starsColor, 405px 926px $starsColor, 869px 96px $starsColor,
      1129px 258px $starsColor, 822px 493px $starsColor,
      309px 1679px $starsColor, 706px 1883px $starsColor,
      772px 493px $starsColor, 1793px 981px $starsColor, 1681px 91px $starsColor,
      351px 214px $starsColor, 1348px 1156px $starsColor,
      1027px 1252px $starsColor, 305px 1663px $starsColor, 6px 697px $starsColor,
      468px 507px $starsColor, 1274px 1938px $starsColor, 94px 495px $starsColor,
      139px 765px $starsColor, 1935px 405px $starsColor,
      611px 1863px $starsColor, 1203px 161px $starsColor,
      1695px 234px $starsColor, 203px 1832px $starsColor,
      1858px 1866px $starsColor, 946px 1888px $starsColor,
      1530px 991px $starsColor, 502px 1858px $starsColor,
      1744px 1045px $starsColor, 1205px 1462px $starsColor,
      1510px 502px $starsColor, 1310px 316px $starsColor,
      1802px 83px $starsColor, 553px 424px $starsColor, 39px 56px $starsColor,
      956px 1174px $starsColor, 1199px 1816px $starsColor,
      1859px 242px $starsColor, 748px 1397px $starsColor,
      982px 182px $starsColor, 183px 1389px $starsColor,
      1562px 1510px $starsColor, 1139px 927px $starsColor,
      770px 1131px $starsColor, 1137px 1935px $starsColor,
      406px 1784px $starsColor, 1467px 1861px $starsColor,
      1005px 1294px $starsColor, 477px 1377px $starsColor, 81px 64px $starsColor,
      1013px 558px $starsColor, 838px 1347px $starsColor, 697px 94px $starsColor,
      1295px 98px $starsColor, 1469px 595px $starsColor,
      1440px 1839px $starsColor, 1403px 1851px $starsColor,
      1779px 350px $starsColor, 1009px 1139px $starsColor,
      1960px 193px $starsColor, 831px 344px $starsColor,
      1955px 927px $starsColor, 1343px 1581px $starsColor,
      1587px 619px $starsColor, 54px 1801px $starsColor,
      153px 1890px $starsColor, 1517px 620px $starsColor,
      1270px 1964px $starsColor, 792px 1066px $starsColor,
      177px 130px $starsColor, 1322px 940px $starsColor,
      1088px 1512px $starsColor, 903px 59px $starsColor,
      298px 1170px $starsColor, 1305px 118px $starsColor,
      766px 1015px $starsColor, 668px 1458px $starsColor,
      1370px 1122px $starsColor, 1175px 1031px $starsColor,
      1478px 996px $starsColor, 22px 436px $starsColor,
      1680px 1320px $starsColor, 264px 1198px $starsColor,
      1413px 1836px $starsColor, 609px 838px $starsColor,
      417px 633px $starsColor, 532px 375px $starsColor, 804px 1678px $starsColor,
      1184px 1909px $starsColor, 830px 465px $starsColor,
      1404px 626px $starsColor, 689px 1528px $starsColor,
      1962px 1021px $starsColor, 672px 941px $starsColor,
      1102px 1697px $starsColor, 177px 1657px $starsColor,
      1410px 903px $starsColor, 1304px 171px $starsColor,
      1339px 1537px $starsColor, 1679px 1208px $starsColor,
      416px 1671px $starsColor, 221px 1938px $starsColor,
      1565px 1724px $starsColor, 1468px 1015px $starsColor,
      294px 1360px $starsColor, 173px 1534px $starsColor,
      1484px 1131px $starsColor, 1323px 640px $starsColor,
      233px 463px $starsColor, 870px 1880px $starsColor,
      1915px 477px $starsColor, 224px 406px $starsColor,
      1127px 1841px $starsColor, 49px 16px $starsColor, 511px 1360px $starsColor,
      785px 1886px $starsColor, 715px 408px $starsColor,
      913px 1088px $starsColor, 1573px 383px $starsColor,
      1431px 619px $starsColor, 830px 1344px $starsColor,
      1970px 1495px $starsColor, 1493px 698px $starsColor,
      2000px 668px $starsColor, 1207px 901px $starsColor, 470px 32px $starsColor,
      306px 41px $starsColor, 192px 696px $starsColor, 930px 1986px $starsColor,
      1338px 796px $starsColor, 795px 1147px $starsColor,
      1617px 579px $starsColor, 1810px 722px $starsColor,
      1270px 50px $starsColor, 1711px 53px $starsColor, 1317px 69px $starsColor,
      726px 375px $starsColor, 1880px 917px $starsColor, 869px 243px $starsColor,
      1241px 337px $starsColor, 700px 1379px $starsColor,
      346px 252px $starsColor, 381px 406px $starsColor, 616px 511px $starsColor,
      846px 1507px $starsColor, 1465px 1115px $starsColor,
      239px 782px $starsColor, 1103px 957px $starsColor,
      900px 1706px $starsColor, 976px 1666px $starsColor,
      1635px 1338px $starsColor, 1175px 1102px $starsColor,
      770px 1146px $starsColor, 1270px 1644px $starsColor,
      1261px 630px $starsColor, 708px 976px $starsColor, 556px 958px $starsColor,
      259px 1237px $starsColor, 1176px 486px $starsColor,
      1389px 232px $starsColor, 1623px 703px $starsColor,
      165px 954px $starsColor, 1049px 795px $starsColor,
      826px 1122px $starsColor, 1056px 430px $starsColor,
      1678px 477px $starsColor, 828px 69px $starsColor, 492px 1445px $starsColor,
      1935px 1484px $starsColor, 667px 1880px $starsColor,
      391px 710px $starsColor, 1407px 131px $starsColor, 437px 370px $starsColor,
      54px 492px $starsColor, 611px 1068px $starsColor, 250px 1509px $starsColor,
      334px 643px $starsColor, 187px 1876px $starsColor,
      1272px 1914px $starsColor, 750px 909px $starsColor,
      1324px 1247px $starsColor, 311px 1315px $starsColor,
      1104px 667px $starsColor, 349px 293px $starsColor,
      624px 1333px $starsColor, 825px 848px $starsColor, 953px 980px $starsColor,
      935px 443px $starsColor, 1339px 356px $starsColor,
      511px 1926px $starsColor, 351px 116px $starsColor,
      1281px 1982px $starsColor, 1379px 800px $starsColor,
      1879px 1648px $starsColor, 815px 1327px $starsColor,
      1587px 1262px $starsColor, 1821px 503px $starsColor,
      749px 1976px $starsColor, 1389px 469px $starsColor,
      239px 949px $starsColor, 1449px 1443px $starsColor, 1688px 4px $starsColor,
      1052px 35px $starsColor, 1079px 1374px $starsColor, 80px 275px $starsColor,
      364px 1854px $starsColor, 152px 1621px $starsColor,
      405px 641px $starsColor, 1047px 232px $starsColor,
      1025px 195px $starsColor, 598px 1026px $starsColor,
      1770px 1245px $starsColor, 929px 983px $starsColor,
      824px 1639px $starsColor, 1863px 99px $starsColor, 556px 662px $starsColor,
      1551px 1833px $starsColor, 104px 1802px $starsColor,
      728px 454px $starsColor, 278px 404px $starsColor, 640px 769px $starsColor,
      1004px 69px $starsColor, 824px 1377px $starsColor,
      1607px 843px $starsColor, 188px 662px $starsColor,
      1824px 319px $starsColor, 1639px 1115px $starsColor,
      1710px 768px $starsColor, 640px 1723px $starsColor,
      1513px 1422px $starsColor, 1069px 36px $starsColor,
      1891px 1940px $starsColor, 727px 121px $starsColor,
      1416px 729px $starsColor, 1679px 1083px $starsColor,
      1579px 76px $starsColor, 74px 768px $starsColor, 1186px 340px $starsColor,
      757px 110px $starsColor, 1477px 425px $starsColor,
      1701px 245px $starsColor, 151px 547px $starsColor,
      1003px 365px $starsColor, 897px 1225px $starsColor, 29px 364px $starsColor,
      1628px 1046px $starsColor, 187px 54px $starsColor,
      542px 1769px $starsColor, 282px 1491px $starsColor,
      855px 419px $starsColor, 391px 76px $starsColor, 468px 847px $starsColor,
      1970px 1579px $starsColor, 1593px 209px $starsColor,
      1395px 908px $starsColor, 1570px 1649px $starsColor,
      859px 1157px $starsColor, 1270px 932px $starsColor,
      101px 1128px $starsColor, 1956px 618px $starsColor,
      1718px 118px $starsColor, 1879px 842px $starsColor,
      870px 1657px $starsColor, 580px 1685px $starsColor,
      1387px 1826px $starsColor, 504px 371px $starsColor, 6px 1742px $starsColor,
      1643px 499px $starsColor, 1060px 224px $starsColor,
      420px 1880px $starsColor, 310px 889px $starsColor,
      1509px 1105px $starsColor, 276px 131px $starsColor,
      336px 1626px $starsColor, 1623px 881px $starsColor,
      1095px 824px $starsColor, 1642px 674px $starsColor,
      1363px 1519px $starsColor, 97px 1275px $starsColor,
      547px 916px $starsColor, 672px 211px $starsColor, 895px 1158px $starsColor,
      309px 1435px $starsColor, 1698px 1621px $starsColor,
      878px 533px $starsColor, 890px 1357px $starsColor,
      525px 1411px $starsColor, 1059px 1324px $starsColor,
      507px 1710px $starsColor, 228px 1600px $starsColor,
      1038px 317px $starsColor, 1915px 1433px $starsColor,
      1266px 132px $starsColor, 512px 762px $starsColor,
      1697px 1431px $starsColor, 185px 428px $starsColor,
      486px 1415px $starsColor, 151px 478px $starsColor,
      1265px 1962px $starsColor, 661px 390px $starsColor,
      1615px 549px $starsColor, 320px 1349px $starsColor, 8px 1354px $starsColor,
      1460px 456px $starsColor, 1404px 906px $starsColor,
      139px 1471px $starsColor, 1528px 1163px $starsColor,
      859px 1411px $starsColor, 888px 171px $starsColor,
      730px 1995px $starsColor, 1222px 1770px $starsColor,
      1940px 957px $starsColor, 458px 1824px $starsColor,
      1313px 479px $starsColor, 660px 1755px $starsColor,
      650px 338px $starsColor, 935px 512px $starsColor, 1758px 487px $starsColor,
      1369px 1287px $starsColor, 1431px 1163px $starsColor,
      393px 661px $starsColor, 1586px 158px $starsColor,
      1107px 510px $starsColor, 943px 940px $starsColor,
      217px 1611px $starsColor, 383px 545px $starsColor, 3px 1679px $starsColor,
      1155px 645px $starsColor, 604px 959px $starsColor,
      1663px 435px $starsColor, 847px 1111px $starsColor,
      1829px 200px $starsColor, 1294px 1318px $starsColor,
      1907px 1734px $starsColor, 1011px 1966px $starsColor,
      155px 800px $starsColor, 668px 331px $starsColor, 1637px 710px $starsColor,
      1761px 1206px $starsColor, 856px 1485px $starsColor,
      1131px 1160px $starsColor, 711px 57px $starsColor,
      1834px 1914px $starsColor, 1748px 1546px $starsColor,
      1445px 1439px $starsColor, 1965px 1584px $starsColor,
      552px 223px $starsColor, 731px 950px $starsColor, 234px 956px $starsColor,
      613px 813px $starsColor, 372px 135px $starsColor, 1835px 590px $starsColor,
      757px 1775px $starsColor, 739px 1726px $starsColor,
      329px 974px $starsColor, 1345px 528px $starsColor, 879px 730px $starsColor,
      88px 1760px $starsColor, 1025px 9px $starsColor, 1764px 1029px $starsColor,
      240px 638px $starsColor, 1404px 1457px $starsColor,
      1237px 1997px $starsColor, 1561px 939px $starsColor,
      492px 1491px $starsColor, 645px 737px $starsColor,
      1760px 1252px $starsColor, 173px 1316px $starsColor,
      388px 1973px $starsColor, 141px 1179px $starsColor,
      434px 1974px $starsColor, 6px 1190px $starsColor;
    animation: animStar 50s linear infinite;
  }
  #stars:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: 1589px 771px $starsColor, 363px 759px $starsColor,
      1535px 66px $starsColor, 321px 540px $starsColor, 1096px 99px $starsColor,
      1112px 848px $starsColor, 1475px 1809px $starsColor,
      1570px 1059px $starsColor, 1100px 119px $starsColor,
      1193px 1745px $starsColor, 1262px 1036px $starsColor,
      1711px 102px $starsColor, 1872px 69px $starsColor,
      1832px 1794px $starsColor, 65px 1306px $starsColor,
      496px 1659px $starsColor, 1997px 526px $starsColor,
      540px 1773px $starsColor, 41px 700px $starsColor,
      1924px 1817px $starsColor, 1424px 765px $starsColor,
      1125px 1213px $starsColor, 291px 243px $starsColor,
      1700px 40px $starsColor, 90px 526px $starsColor, 468px 1181px $starsColor,
      176px 1172px $starsColor, 550px 111px $starsColor,
      1746px 958px $starsColor, 829px 1898px $starsColor,
      1197px 932px $starsColor, 1147px 950px $starsColor,
      397px 1236px $starsColor, 1684px 1379px $starsColor,
      1362px 1358px $starsColor, 1457px 202px $starsColor,
      1467px 1894px $starsColor, 1950px 94px $starsColor,
      1567px 1288px $starsColor, 990px 1266px $starsColor,
      1866px 25px $starsColor, 1099px 1691px $starsColor,
      876px 660px $starsColor, 325px 1645px $starsColor,
      1578px 1612px $starsColor, 1269px 1289px $starsColor,
      1771px 367px $starsColor, 498px 746px $starsColor,
      1260px 836px $starsColor, 1002px 264px $starsColor,
      1731px 1234px $starsColor, 1389px 1899px $starsColor,
      1447px 149px $starsColor, 713px 250px $starsColor, 579px 497px $starsColor,
      1853px 383px $starsColor, 1816px 1558px $starsColor,
      1050px 468px $starsColor, 760px 1492px $starsColor,
      1322px 298px $starsColor, 1198px 1619px $starsColor,
      31px 167px $starsColor, 580px 880px $starsColor, 140px 1810px $starsColor,
      926px 1199px $starsColor, 1462px 1206px $starsColor,
      260px 1843px $starsColor, 411px 415px $starsColor,
      1583px 486px $starsColor, 727px 836px $starsColor,
      309px 1831px $starsColor, 1521px 399px $starsColor,
      364px 164px $starsColor, 309px 1961px $starsColor, 217px 749px $starsColor,
      1395px 641px $starsColor, 1678px 545px $starsColor,
      757px 1759px $starsColor, 1165px 1716px $starsColor,
      1603px 1013px $starsColor, 977px 765px $starsColor,
      581px 765px $starsColor, 1419px 1880px $starsColor,
      426px 557px $starsColor, 7px 885px $starsColor, 699px 1934px $starsColor,
      40px 1899px $starsColor, 1509px 696px $starsColor, 131px 442px $starsColor,
      505px 840px $starsColor, 536px 513px $starsColor,
      1942px 1751px $starsColor, 1949px 915px $starsColor,
      1986px 391px $starsColor, 1875px 1303px $starsColor,
      363px 471px $starsColor, 847px 1250px $starsColor,
      1180px 1548px $starsColor, 427px 379px $starsColor,
      714px 892px $starsColor, 1624px 1754px $starsColor,
      1268px 328px $starsColor, 1651px 1562px $starsColor,
      1823px 1799px $starsColor, 461px 1294px $starsColor,
      1656px 1455px $starsColor, 256px 1903px $starsColor,
      547px 189px $starsColor, 1043px 133px $starsColor,
      740px 1288px $starsColor, 1552px 1504px $starsColor,
      558px 823px $starsColor, 1660px 1735px $starsColor,
      1506px 342px $starsColor, 165px 1009px $starsColor,
      1266px 260px $starsColor, 163px 1646px $starsColor,
      772px 1376px $starsColor, 1026px 672px $starsColor,
      608px 728px $starsColor, 420px 1311px $starsColor,
      1065px 1956px $starsColor, 1156px 154px $starsColor,
      1014px 442px $starsColor, 526px 98px $starsColor, 305px 854px $starsColor,
      1104px 44px $starsColor, 169px 1861px $starsColor,
      1556px 465px $starsColor, 493px 1327px $starsColor,
      965px 1094px $starsColor, 62px 1728px $starsColor, 123px 285px $starsColor,
      791px 1204px $starsColor, 1226px 620px $starsColor,
      848px 696px $starsColor, 1500px 501px $starsColor,
      1834px 1034px $starsColor, 1479px 381px $starsColor,
      978px 103px $starsColor, 1082px 166px $starsColor,
      1628px 399px $starsColor, 776px 628px $starsColor,
      1077px 1058px $starsColor, 1027px 136px $starsColor,
      172px 301px $starsColor, 1108px 1490px $starsColor,
      1682px 1917px $starsColor, 579px 293px $starsColor,
      848px 1353px $starsColor, 817px 780px $starsColor,
      1874px 718px $starsColor, 117px 1123px $starsColor,
      1491px 886px $starsColor, 1593px 195px $starsColor,
      308px 1583px $starsColor, 1491px 1781px $starsColor,
      990px 1073px $starsColor, 958px 57px $starsColor, 72px 1093px $starsColor,
      1562px 36px $starsColor, 129px 1640px $starsColor,
      1727px 1647px $starsColor, 1842px 364px $starsColor,
      1919px 1206px $starsColor, 1817px 1613px $starsColor,
      1270px 674px $starsColor, 836px 892px $starsColor,
      1733px 267px $starsColor, 731px 1663px $starsColor,
      1483px 1039px $starsColor, 467px 109px $starsColor,
      882px 1827px $starsColor, 161px 19px $starsColor, 260px 997px $starsColor,
      130px 1652px $starsColor, 1916px 368px $starsColor,
      286px 1044px $starsColor, 1424px 617px $starsColor,
      1302px 1140px $starsColor, 16px 493px $starsColor,
      1509px 1341px $starsColor, 433px 959px $starsColor,
      297px 166px $starsColor, 113px 310px $starsColor, 137px 1113px $starsColor,
      778px 994px $starsColor, 1786px 674px $starsColor,
      417px 1765px $starsColor, 1036px 1927px $starsColor,
      1773px 74px $starsColor, 1877px 1629px $starsColor,
      1229px 649px $starsColor, 1939px 1209px $starsColor,
      505px 227px $starsColor, 403px 1505px $starsColor,
      735px 1953px $starsColor, 1059px 1463px $starsColor,
      396px 604px $starsColor, 827px 1193px $starsColor,
      1812px 1359px $starsColor, 1604px 328px $starsColor,
      122px 668px $starsColor, 417px 1400px $starsColor,
      1251px 433px $starsColor, 1484px 573px $starsColor,
      1048px 1870px $starsColor, 1450px 241px $starsColor,
      943px 1418px $starsColor, 465px 1378px $starsColor,
      1111px 1396px $starsColor, 1225px 1639px $starsColor,
      583px 1142px $starsColor, 722px 1611px $starsColor,
      678px 979px $starsColor, 1993px 432px $starsColor, 87px 106px $starsColor,
      1482px 1736px $starsColor, 638px 954px $starsColor,
      718px 1255px $starsColor, 492px 1220px $starsColor,
      1407px 28px $starsColor, 1063px 208px $starsColor, 401px 33px $starsColor,
      425px 984px $starsColor, 1139px 933px $starsColor, 194px 547px $starsColor,
      196px 889px $starsColor, 830px 1618px $starsColor, 692px 397px $starsColor,
      11px 174px $starsColor, 551px 1533px $starsColor,
      1385px 1578px $starsColor, 1932px 385px $starsColor,
      1065px 1445px $starsColor, 1574px 398px $starsColor,
      1517px 769px $starsColor, 1764px 501px $starsColor,
      1141px 1880px $starsColor, 54px 39px $starsColor, 940px 1363px $starsColor,
      777px 1645px $starsColor, 244px 31px $starsColor, 1503px 880px $starsColor,
      27px 681px $starsColor, 796px 1725px $starsColor, 429px 801px $starsColor,
      693px 862px $starsColor, 1825px 1936px $starsColor,
      425px 471px $starsColor, 566px 649px $starsColor, 641px 21px $starsColor,
      1200px 1044px $starsColor, 1977px 1161px $starsColor,
      1359px 1809px $starsColor, 1641px 986px $starsColor,
      760px 1080px $starsColor, 1822px 1644px $starsColor,
      904px 443px $starsColor, 1500px 1620px $starsColor,
      1654px 1215px $starsColor, 998px 1598px $starsColor,
      1187px 395px $starsColor, 1680px 298px $starsColor,
      1420px 1411px $starsColor, 374px 374px $starsColor,
      878px 1812px $starsColor, 420px 1018px $starsColor,
      222px 1659px $starsColor, 1983px 313px $starsColor,
      1464px 1908px $starsColor, 1873px 1221px $starsColor,
      1801px 1630px $starsColor, 1497px 189px $starsColor,
      1016px 649px $starsColor, 237px 1295px $starsColor,
      977px 780px $starsColor, 1355px 651px $starsColor, 791px 696px $starsColor,
      1666px 566px $starsColor, 370px 1233px $starsColor,
      671px 1332px $starsColor, 1517px 1043px $starsColor,
      324px 541px $starsColor, 707px 1862px $starsColor,
      771px 1212px $starsColor, 1243px 1705px $starsColor,
      604px 1123px $starsColor, 1773px 1979px $starsColor,
      1509px 340px $starsColor, 1869px 143px $starsColor,
      725px 604px $starsColor, 1650px 946px $starsColor,
      1625px 246px $starsColor, 402px 1031px $starsColor,
      1231px 288px $starsColor, 1510px 504px $starsColor,
      1051px 1247px $starsColor, 277px 164px $starsColor,
      1149px 1314px $starsColor, 1483px 527px $starsColor,
      164px 1007px $starsColor, 217px 1036px $starsColor,
      1512px 1288px $starsColor, 607px 1423px $starsColor,
      1019px 296px $starsColor, 192px 1409px $starsColor,
      259px 648px $starsColor, 501px 1720px $starsColor,
      1868px 1308px $starsColor, 430px 1976px $starsColor,
      918px 1676px $starsColor, 925px 1672px $starsColor,
      242px 1315px $starsColor, 1485px 1922px $starsColor,
      1960px 103px $starsColor, 778px 1808px $starsColor,
      43px 1857px $starsColor, 1382px 283px $starsColor,
      1040px 1452px $starsColor, 497px 1019px $starsColor,
      287px 668px $starsColor, 396px 875px $starsColor, 1956px 971px $starsColor,
      1456px 1219px $starsColor, 1047px 803px $starsColor,
      1795px 72px $starsColor, 1465px 333px $starsColor,
      948px 1899px $starsColor, 405px 926px $starsColor, 869px 96px $starsColor,
      1129px 258px $starsColor, 822px 493px $starsColor,
      309px 1679px $starsColor, 706px 1883px $starsColor,
      772px 493px $starsColor, 1793px 981px $starsColor, 1681px 91px $starsColor,
      351px 214px $starsColor, 1348px 1156px $starsColor,
      1027px 1252px $starsColor, 305px 1663px $starsColor, 6px 697px $starsColor,
      468px 507px $starsColor, 1274px 1938px $starsColor, 94px 495px $starsColor,
      139px 765px $starsColor, 1935px 405px $starsColor,
      611px 1863px $starsColor, 1203px 161px $starsColor,
      1695px 234px $starsColor, 203px 1832px $starsColor,
      1858px 1866px $starsColor, 946px 1888px $starsColor,
      1530px 991px $starsColor, 502px 1858px $starsColor,
      1744px 1045px $starsColor, 1205px 1462px $starsColor,
      1510px 502px $starsColor, 1310px 316px $starsColor,
      1802px 83px $starsColor, 553px 424px $starsColor, 39px 56px $starsColor,
      956px 1174px $starsColor, 1199px 1816px $starsColor,
      1859px 242px $starsColor, 748px 1397px $starsColor,
      982px 182px $starsColor, 183px 1389px $starsColor,
      1562px 1510px $starsColor, 1139px 927px $starsColor,
      770px 1131px $starsColor, 1137px 1935px $starsColor,
      406px 1784px $starsColor, 1467px 1861px $starsColor,
      1005px 1294px $starsColor, 477px 1377px $starsColor, 81px 64px $starsColor,
      1013px 558px $starsColor, 838px 1347px $starsColor, 697px 94px $starsColor,
      1295px 98px $starsColor, 1469px 595px $starsColor,
      1440px 1839px $starsColor, 1403px 1851px $starsColor,
      1779px 350px $starsColor, 1009px 1139px $starsColor,
      1960px 193px $starsColor, 831px 344px $starsColor,
      1955px 927px $starsColor, 1343px 1581px $starsColor,
      1587px 619px $starsColor, 54px 1801px $starsColor,
      153px 1890px $starsColor, 1517px 620px $starsColor,
      1270px 1964px $starsColor, 792px 1066px $starsColor,
      177px 130px $starsColor, 1322px 940px $starsColor,
      1088px 1512px $starsColor, 903px 59px $starsColor,
      298px 1170px $starsColor, 1305px 118px $starsColor,
      766px 1015px $starsColor, 668px 1458px $starsColor,
      1370px 1122px $starsColor, 1175px 1031px $starsColor,
      1478px 996px $starsColor, 22px 436px $starsColor,
      1680px 1320px $starsColor, 264px 1198px $starsColor,
      1413px 1836px $starsColor, 609px 838px $starsColor,
      417px 633px $starsColor, 532px 375px $starsColor, 804px 1678px $starsColor,
      1184px 1909px $starsColor, 830px 465px $starsColor,
      1404px 626px $starsColor, 689px 1528px $starsColor,
      1962px 1021px $starsColor, 672px 941px $starsColor,
      1102px 1697px $starsColor, 177px 1657px $starsColor,
      1410px 903px $starsColor, 1304px 171px $starsColor,
      1339px 1537px $starsColor, 1679px 1208px $starsColor,
      416px 1671px $starsColor, 221px 1938px $starsColor,
      1565px 1724px $starsColor, 1468px 1015px $starsColor,
      294px 1360px $starsColor, 173px 1534px $starsColor,
      1484px 1131px $starsColor, 1323px 640px $starsColor,
      233px 463px $starsColor, 870px 1880px $starsColor,
      1915px 477px $starsColor, 224px 406px $starsColor,
      1127px 1841px $starsColor, 49px 16px $starsColor, 511px 1360px $starsColor,
      785px 1886px $starsColor, 715px 408px $starsColor,
      913px 1088px $starsColor, 1573px 383px $starsColor,
      1431px 619px $starsColor, 830px 1344px $starsColor,
      1970px 1495px $starsColor, 1493px 698px $starsColor,
      2000px 668px $starsColor, 1207px 901px $starsColor, 470px 32px $starsColor,
      306px 41px $starsColor, 192px 696px $starsColor, 930px 1986px $starsColor,
      1338px 796px $starsColor, 795px 1147px $starsColor,
      1617px 579px $starsColor, 1810px 722px $starsColor,
      1270px 50px $starsColor, 1711px 53px $starsColor, 1317px 69px $starsColor,
      726px 375px $starsColor, 1880px 917px $starsColor, 869px 243px $starsColor,
      1241px 337px $starsColor, 700px 1379px $starsColor,
      346px 252px $starsColor, 381px 406px $starsColor, 616px 511px $starsColor,
      846px 1507px $starsColor, 1465px 1115px $starsColor,
      239px 782px $starsColor, 1103px 957px $starsColor,
      900px 1706px $starsColor, 976px 1666px $starsColor,
      1635px 1338px $starsColor, 1175px 1102px $starsColor,
      770px 1146px $starsColor, 1270px 1644px $starsColor,
      1261px 630px $starsColor, 708px 976px $starsColor, 556px 958px $starsColor,
      259px 1237px $starsColor, 1176px 486px $starsColor,
      1389px 232px $starsColor, 1623px 703px $starsColor,
      165px 954px $starsColor, 1049px 795px $starsColor,
      826px 1122px $starsColor, 1056px 430px $starsColor,
      1678px 477px $starsColor, 828px 69px $starsColor, 492px 1445px $starsColor,
      1935px 1484px $starsColor, 667px 1880px $starsColor,
      391px 710px $starsColor, 1407px 131px $starsColor, 437px 370px $starsColor,
      54px 492px $starsColor, 611px 1068px $starsColor, 250px 1509px $starsColor,
      334px 643px $starsColor, 187px 1876px $starsColor,
      1272px 1914px $starsColor, 750px 909px $starsColor,
      1324px 1247px $starsColor, 311px 1315px $starsColor,
      1104px 667px $starsColor, 349px 293px $starsColor,
      624px 1333px $starsColor, 825px 848px $starsColor, 953px 980px $starsColor,
      935px 443px $starsColor, 1339px 356px $starsColor,
      511px 1926px $starsColor, 351px 116px $starsColor,
      1281px 1982px $starsColor, 1379px 800px $starsColor,
      1879px 1648px $starsColor, 815px 1327px $starsColor,
      1587px 1262px $starsColor, 1821px 503px $starsColor,
      749px 1976px $starsColor, 1389px 469px $starsColor,
      239px 949px $starsColor, 1449px 1443px $starsColor, 1688px 4px $starsColor,
      1052px 35px $starsColor, 1079px 1374px $starsColor, 80px 275px $starsColor,
      364px 1854px $starsColor, 152px 1621px $starsColor,
      405px 641px $starsColor, 1047px 232px $starsColor,
      1025px 195px $starsColor, 598px 1026px $starsColor,
      1770px 1245px $starsColor, 929px 983px $starsColor,
      824px 1639px $starsColor, 1863px 99px $starsColor, 556px 662px $starsColor,
      1551px 1833px $starsColor, 104px 1802px $starsColor,
      728px 454px $starsColor, 278px 404px $starsColor, 640px 769px $starsColor,
      1004px 69px $starsColor, 824px 1377px $starsColor,
      1607px 843px $starsColor, 188px 662px $starsColor,
      1824px 319px $starsColor, 1639px 1115px $starsColor,
      1710px 768px $starsColor, 640px 1723px $starsColor,
      1513px 1422px $starsColor, 1069px 36px $starsColor,
      1891px 1940px $starsColor, 727px 121px $starsColor,
      1416px 729px $starsColor, 1679px 1083px $starsColor,
      1579px 76px $starsColor, 74px 768px $starsColor, 1186px 340px $starsColor,
      757px 110px $starsColor, 1477px 425px $starsColor,
      1701px 245px $starsColor, 151px 547px $starsColor,
      1003px 365px $starsColor, 897px 1225px $starsColor, 29px 364px $starsColor,
      1628px 1046px $starsColor, 187px 54px $starsColor,
      542px 1769px $starsColor, 282px 1491px $starsColor,
      855px 419px $starsColor, 391px 76px $starsColor, 468px 847px $starsColor,
      1970px 1579px $starsColor, 1593px 209px $starsColor,
      1395px 908px $starsColor, 1570px 1649px $starsColor,
      859px 1157px $starsColor, 1270px 932px $starsColor,
      101px 1128px $starsColor, 1956px 618px $starsColor,
      1718px 118px $starsColor, 1879px 842px $starsColor,
      870px 1657px $starsColor, 580px 1685px $starsColor,
      1387px 1826px $starsColor, 504px 371px $starsColor, 6px 1742px $starsColor,
      1643px 499px $starsColor, 1060px 224px $starsColor,
      420px 1880px $starsColor, 310px 889px $starsColor,
      1509px 1105px $starsColor, 276px 131px $starsColor,
      336px 1626px $starsColor, 1623px 881px $starsColor,
      1095px 824px $starsColor, 1642px 674px $starsColor,
      1363px 1519px $starsColor, 97px 1275px $starsColor,
      547px 916px $starsColor, 672px 211px $starsColor, 895px 1158px $starsColor,
      309px 1435px $starsColor, 1698px 1621px $starsColor,
      878px 533px $starsColor, 890px 1357px $starsColor,
      525px 1411px $starsColor, 1059px 1324px $starsColor,
      507px 1710px $starsColor, 228px 1600px $starsColor,
      1038px 317px $starsColor, 1915px 1433px $starsColor,
      1266px 132px $starsColor, 512px 762px $starsColor,
      1697px 1431px $starsColor, 185px 428px $starsColor,
      486px 1415px $starsColor, 151px 478px $starsColor,
      1265px 1962px $starsColor, 661px 390px $starsColor,
      1615px 549px $starsColor, 320px 1349px $starsColor, 8px 1354px $starsColor,
      1460px 456px $starsColor, 1404px 906px $starsColor,
      139px 1471px $starsColor, 1528px 1163px $starsColor,
      859px 1411px $starsColor, 888px 171px $starsColor,
      730px 1995px $starsColor, 1222px 1770px $starsColor,
      1940px 957px $starsColor, 458px 1824px $starsColor,
      1313px 479px $starsColor, 660px 1755px $starsColor,
      650px 338px $starsColor, 935px 512px $starsColor, 1758px 487px $starsColor,
      1369px 1287px $starsColor, 1431px 1163px $starsColor,
      393px 661px $starsColor, 1586px 158px $starsColor,
      1107px 510px $starsColor, 943px 940px $starsColor,
      217px 1611px $starsColor, 383px 545px $starsColor, 3px 1679px $starsColor,
      1155px 645px $starsColor, 604px 959px $starsColor,
      1663px 435px $starsColor, 847px 1111px $starsColor,
      1829px 200px $starsColor, 1294px 1318px $starsColor,
      1907px 1734px $starsColor, 1011px 1966px $starsColor,
      155px 800px $starsColor, 668px 331px $starsColor, 1637px 710px $starsColor,
      1761px 1206px $starsColor, 856px 1485px $starsColor,
      1131px 1160px $starsColor, 711px 57px $starsColor,
      1834px 1914px $starsColor, 1748px 1546px $starsColor,
      1445px 1439px $starsColor, 1965px 1584px $starsColor,
      552px 223px $starsColor, 731px 950px $starsColor, 234px 956px $starsColor,
      613px 813px $starsColor, 372px 135px $starsColor, 1835px 590px $starsColor,
      757px 1775px $starsColor, 739px 1726px $starsColor,
      329px 974px $starsColor, 1345px 528px $starsColor, 879px 730px $starsColor,
      88px 1760px $starsColor, 1025px 9px $starsColor, 1764px 1029px $starsColor,
      240px 638px $starsColor, 1404px 1457px $starsColor,
      1237px 1997px $starsColor, 1561px 939px $starsColor,
      492px 1491px $starsColor, 645px 737px $starsColor,
      1760px 1252px $starsColor, 173px 1316px $starsColor,
      388px 1973px $starsColor, 141px 1179px $starsColor,
      434px 1974px $starsColor, 6px 1190px $starsColor;
  }
  #stars2 {
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: 1051px 1958px $starsColor, 1207px 1515px $starsColor,
      1658px 1270px $starsColor, 893px 1075px $starsColor,
      872px 686px $starsColor, 1222px 1831px $starsColor, 94px 629px $starsColor,
      449px 178px $starsColor, 489px 679px $starsColor, 396px 1223px $starsColor,
      188px 1222px $starsColor, 59px 733px $starsColor,
      1494px 1157px $starsColor, 615px 188px $starsColor,
      1109px 1115px $starsColor, 1843px 1464px $starsColor,
      1658px 562px $starsColor, 1833px 1092px $starsColor,
      225px 776px $starsColor, 835px 198px $starsColor, 1640px 175px $starsColor,
      1845px 291px $starsColor, 53px 175px $starsColor,
      1550px 1274px $starsColor, 709px 721px $starsColor,
      254px 1595px $starsColor, 1016px 1643px $starsColor,
      52px 1604px $starsColor, 1221px 1900px $starsColor,
      906px 758px $starsColor, 1296px 1646px $starsColor,
      1208px 1491px $starsColor, 413px 1063px $starsColor,
      1406px 21px $starsColor, 618px 54px $starsColor, 510px 494px $starsColor,
      1248px 329px $starsColor, 876px 974px $starsColor,
      1665px 516px $starsColor, 498px 1348px $starsColor,
      1947px 828px $starsColor, 33px 985px $starsColor, 1260px 220px $starsColor,
      1726px 1619px $starsColor, 1586px 1025px $starsColor,
      161px 1877px $starsColor, 1257px 732px $starsColor,
      580px 1594px $starsColor, 1842px 301px $starsColor,
      1625px 118px $starsColor, 603px 1081px $starsColor,
      514px 1328px $starsColor, 1760px 1788px $starsColor,
      994px 626px $starsColor, 1974px 1219px $starsColor,
      1172px 302px $starsColor, 263px 781px $starsColor,
      805px 1622px $starsColor, 775px 1008px $starsColor,
      571px 692px $starsColor, 1302px 1252px $starsColor,
      847px 861px $starsColor, 1155px 1143px $starsColor,
      1168px 402px $starsColor, 1000px 1093px $starsColor,
      962px 958px $starsColor, 1004px 1222px $starsColor,
      1445px 1138px $starsColor, 306px 1923px $starsColor,
      696px 1527px $starsColor, 1822px 191px $starsColor,
      245px 1508px $starsColor, 1333px 32px $starsColor, 476px 448px $starsColor,
      591px 1500px $starsColor, 1193px 1235px $starsColor,
      1330px 806px $starsColor, 418px 1357px $starsColor,
      1583px 270px $starsColor, 765px 605px $starsColor, 1167px 11px $starsColor,
      763px 1852px $starsColor, 637px 1288px $starsColor,
      1217px 1958px $starsColor, 731px 1087px $starsColor,
      1649px 578px $starsColor, 265px 1956px $starsColor,
      1060px 1614px $starsColor, 804px 496px $starsColor,
      1595px 1625px $starsColor, 1942px 1829px $starsColor,
      1514px 1769px $starsColor, 208px 1925px $starsColor,
      441px 856px $starsColor, 9px 251px $starsColor, 34px 615px $starsColor,
      994px 1541px $starsColor, 1552px 1366px $starsColor,
      1571px 1916px $starsColor, 395px 1265px $starsColor,
      1568px 22px $starsColor, 1667px 1478px $starsColor,
      1062px 1545px $starsColor, 1989px 1631px $starsColor,
      1803px 1021px $starsColor, 60px 225px $starsColor,
      543px 1738px $starsColor, 1993px 540px $starsColor,
      929px 1947px $starsColor, 1124px 1379px $starsColor,
      930px 1826px $starsColor, 1310px 1827px $starsColor,
      1185px 375px $starsColor, 1690px 825px $starsColor,
      1501px 835px $starsColor, 1676px 566px $starsColor,
      522px 265px $starsColor, 1729px 289px $starsColor,
      1566px 773px $starsColor, 756px 640px $starsColor,
      1741px 1466px $starsColor, 1897px 624px $starsColor,
      1072px 1004px $starsColor, 314px 957px $starsColor,
      1449px 932px $starsColor, 601px 1196px $starsColor,
      1253px 669px $starsColor, 362px 1787px $starsColor,
      1804px 1834px $starsColor, 377px 1749px $starsColor,
      671px 1631px $starsColor, 1626px 493px $starsColor,
      1913px 909px $starsColor, 1328px 16px $starsColor, 44px 659px $starsColor,
      1929px 885px $starsColor, 116px 1102px $starsColor,
      636px 1753px $starsColor, 1779px 886px $starsColor,
      573px 670px $starsColor, 1613px 647px $starsColor,
      1747px 886px $starsColor, 1193px 856px $starsColor,
      316px 875px $starsColor, 1595px 485px $starsColor, 302px 383px $starsColor,
      1559px 1206px $starsColor, 1372px 198px $starsColor,
      1359px 862px $starsColor, 45px 1521px $starsColor,
      405px 1484px $starsColor, 1356px 366px $starsColor,
      333px 343px $starsColor, 793px 1724px $starsColor, 692px 404px $starsColor,
      968px 771px $starsColor, 408px 693px $starsColor, 554px 126px $starsColor,
      1490px 1965px $starsColor, 1477px 1267px $starsColor,
      1757px 701px $starsColor, 71px 1484px $starsColor,
      1621px 896px $starsColor, 168px 307px $starsColor,
      1590px 1629px $starsColor, 1795px 179px $starsColor,
      505px 396px $starsColor, 896px 64px $starsColor, 430px 1062px $starsColor,
      1485px 760px $starsColor, 421px 1328px $starsColor,
      1289px 805px $starsColor, 28px 848px $starsColor, 1278px 14px $starsColor,
      1713px 725px $starsColor, 1274px 1705px $starsColor,
      616px 1426px $starsColor, 1651px 1316px $starsColor,
      1926px 806px $starsColor, 457px 1998px $starsColor,
      1115px 1967px $starsColor, 1414px 233px $starsColor,
      945px 1278px $starsColor, 611px 616px $starsColor,
      1739px 1794px $starsColor, 1704px 1878px $starsColor,
      559px 1071px $starsColor, 456px 1291px $starsColor,
      592px 576px $starsColor, 106px 474px $starsColor, 584px 1122px $starsColor,
      872px 1870px $starsColor, 1673px 1232px $starsColor,
      507px 1493px $starsColor, 154px 1106px $starsColor,
      938px 1165px $starsColor, 100px 437px $starsColor,
      1432px 610px $starsColor, 1698px 1720px $starsColor,
      1126px 1728px $starsColor;
    animation: animStar 100s linear infinite;
  }
  #stars2:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: 1051px 1958px $starsColor, 1207px 1515px $starsColor,
      1658px 1270px $starsColor, 893px 1075px $starsColor,
      872px 686px $starsColor, 1222px 1831px $starsColor, 94px 629px $starsColor,
      449px 178px $starsColor, 489px 679px $starsColor, 396px 1223px $starsColor,
      188px 1222px $starsColor, 59px 733px $starsColor,
      1494px 1157px $starsColor, 615px 188px $starsColor,
      1109px 1115px $starsColor, 1843px 1464px $starsColor,
      1658px 562px $starsColor, 1833px 1092px $starsColor,
      225px 776px $starsColor, 835px 198px $starsColor, 1640px 175px $starsColor,
      1845px 291px $starsColor, 53px 175px $starsColor,
      1550px 1274px $starsColor, 709px 721px $starsColor,
      254px 1595px $starsColor, 1016px 1643px $starsColor,
      52px 1604px $starsColor, 1221px 1900px $starsColor,
      906px 758px $starsColor, 1296px 1646px $starsColor,
      1208px 1491px $starsColor, 413px 1063px $starsColor,
      1406px 21px $starsColor, 618px 54px $starsColor, 510px 494px $starsColor,
      1248px 329px $starsColor, 876px 974px $starsColor,
      1665px 516px $starsColor, 498px 1348px $starsColor,
      1947px 828px $starsColor, 33px 985px $starsColor, 1260px 220px $starsColor,
      1726px 1619px $starsColor, 1586px 1025px $starsColor,
      161px 1877px $starsColor, 1257px 732px $starsColor,
      580px 1594px $starsColor, 1842px 301px $starsColor,
      1625px 118px $starsColor, 603px 1081px $starsColor,
      514px 1328px $starsColor, 1760px 1788px $starsColor,
      994px 626px $starsColor, 1974px 1219px $starsColor,
      1172px 302px $starsColor, 263px 781px $starsColor,
      805px 1622px $starsColor, 775px 1008px $starsColor,
      571px 692px $starsColor, 1302px 1252px $starsColor,
      847px 861px $starsColor, 1155px 1143px $starsColor,
      1168px 402px $starsColor, 1000px 1093px $starsColor,
      962px 958px $starsColor, 1004px 1222px $starsColor,
      1445px 1138px $starsColor, 306px 1923px $starsColor,
      696px 1527px $starsColor, 1822px 191px $starsColor,
      245px 1508px $starsColor, 1333px 32px $starsColor, 476px 448px $starsColor,
      591px 1500px $starsColor, 1193px 1235px $starsColor,
      1330px 806px $starsColor, 418px 1357px $starsColor,
      1583px 270px $starsColor, 765px 605px $starsColor, 1167px 11px $starsColor,
      763px 1852px $starsColor, 637px 1288px $starsColor,
      1217px 1958px $starsColor, 731px 1087px $starsColor,
      1649px 578px $starsColor, 265px 1956px $starsColor,
      1060px 1614px $starsColor, 804px 496px $starsColor,
      1595px 1625px $starsColor, 1942px 1829px $starsColor,
      1514px 1769px $starsColor, 208px 1925px $starsColor,
      441px 856px $starsColor, 9px 251px $starsColor, 34px 615px $starsColor,
      994px 1541px $starsColor, 1552px 1366px $starsColor,
      1571px 1916px $starsColor, 395px 1265px $starsColor,
      1568px 22px $starsColor, 1667px 1478px $starsColor,
      1062px 1545px $starsColor, 1989px 1631px $starsColor,
      1803px 1021px $starsColor, 60px 225px $starsColor,
      543px 1738px $starsColor, 1993px 540px $starsColor,
      929px 1947px $starsColor, 1124px 1379px $starsColor,
      930px 1826px $starsColor, 1310px 1827px $starsColor,
      1185px 375px $starsColor, 1690px 825px $starsColor,
      1501px 835px $starsColor, 1676px 566px $starsColor,
      522px 265px $starsColor, 1729px 289px $starsColor,
      1566px 773px $starsColor, 756px 640px $starsColor,
      1741px 1466px $starsColor, 1897px 624px $starsColor,
      1072px 1004px $starsColor, 314px 957px $starsColor,
      1449px 932px $starsColor, 601px 1196px $starsColor,
      1253px 669px $starsColor, 362px 1787px $starsColor,
      1804px 1834px $starsColor, 377px 1749px $starsColor,
      671px 1631px $starsColor, 1626px 493px $starsColor,
      1913px 909px $starsColor, 1328px 16px $starsColor, 44px 659px $starsColor,
      1929px 885px $starsColor, 116px 1102px $starsColor,
      636px 1753px $starsColor, 1779px 886px $starsColor,
      573px 670px $starsColor, 1613px 647px $starsColor,
      1747px 886px $starsColor, 1193px 856px $starsColor,
      316px 875px $starsColor, 1595px 485px $starsColor, 302px 383px $starsColor,
      1559px 1206px $starsColor, 1372px 198px $starsColor,
      1359px 862px $starsColor, 45px 1521px $starsColor,
      405px 1484px $starsColor, 1356px 366px $starsColor,
      333px 343px $starsColor, 793px 1724px $starsColor, 692px 404px $starsColor,
      968px 771px $starsColor, 408px 693px $starsColor, 554px 126px $starsColor,
      1490px 1965px $starsColor, 1477px 1267px $starsColor,
      1757px 701px $starsColor, 71px 1484px $starsColor,
      1621px 896px $starsColor, 168px 307px $starsColor,
      1590px 1629px $starsColor, 1795px 179px $starsColor,
      505px 396px $starsColor, 896px 64px $starsColor, 430px 1062px $starsColor,
      1485px 760px $starsColor, 421px 1328px $starsColor,
      1289px 805px $starsColor, 28px 848px $starsColor, 1278px 14px $starsColor,
      1713px 725px $starsColor, 1274px 1705px $starsColor,
      616px 1426px $starsColor, 1651px 1316px $starsColor,
      1926px 806px $starsColor, 457px 1998px $starsColor,
      1115px 1967px $starsColor, 1414px 233px $starsColor,
      945px 1278px $starsColor, 611px 616px $starsColor,
      1739px 1794px $starsColor, 1704px 1878px $starsColor,
      559px 1071px $starsColor, 456px 1291px $starsColor,
      592px 576px $starsColor, 106px 474px $starsColor, 584px 1122px $starsColor,
      872px 1870px $starsColor, 1673px 1232px $starsColor,
      507px 1493px $starsColor, 154px 1106px $starsColor,
      938px 1165px $starsColor, 100px 437px $starsColor,
      1432px 610px $starsColor, 1698px 1720px $starsColor,
      1126px 1728px $starsColor;
  }
  #stars3 {
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: 726px 936px $starsColor, 1218px 92px $starsColor,
      1055px 1423px $starsColor, 109px 540px $starsColor,
      1645px 306px $starsColor, 724px 515px $starsColor,
      1637px 1938px $starsColor, 377px 652px $starsColor,
      141px 259px $starsColor, 1949px 71px $starsColor, 1150px 157px $starsColor,
      299px 146px $starsColor, 590px 1453px $starsColor,
      488px 1983px $starsColor, 1229px 1692px $starsColor,
      1270px 282px $starsColor, 1602px 1339px $starsColor,
      178px 1726px $starsColor, 1615px 611px $starsColor,
      916px 1406px $starsColor, 952px 192px $starsColor,
      636px 1290px $starsColor, 304px 1809px $starsColor,
      1472px 1484px $starsColor, 1476px 583px $starsColor,
      1799px 726px $starsColor, 1985px 1650px $starsColor,
      1304px 1009px $starsColor, 1788px 564px $starsColor,
      1631px 1956px $starsColor, 618px 577px $starsColor,
      1419px 1288px $starsColor, 1292px 1498px $starsColor,
      1300px 490px $starsColor, 912px 599px $starsColor,
      1833px 237px $starsColor, 28px 1027px $starsColor,
      1434px 1296px $starsColor, 1812px 250px $starsColor,
      499px 838px $starsColor, 356px 518px $starsColor, 189px 363px $starsColor,
      1039px 1081px $starsColor, 560px 1345px $starsColor,
      1330px 717px $starsColor, 1147px 196px $starsColor,
      739px 822px $starsColor, 99px 1024px $starsColor, 595px 131px $starsColor,
      395px 631px $starsColor, 113px 1541px $starsColor,
      1857px 380px $starsColor, 1322px 1433px $starsColor,
      772px 1685px $starsColor, 1424px 193px $starsColor,
      690px 1405px $starsColor, 11px 1867px $starsColor,
      1171px 225px $starsColor, 404px 407px $starsColor, 650px 193px $starsColor,
      229px 1575px $starsColor, 567px 208px $starsColor,
      1655px 1538px $starsColor, 698px 1739px $starsColor,
      1695px 476px $starsColor, 786px 777px $starsColor, 35px 1005px $starsColor,
      1620px 228px $starsColor, 712px 168px $starsColor,
      1587px 1903px $starsColor, 1241px 850px $starsColor,
      254px 1098px $starsColor, 1850px 888px $starsColor,
      918px 1961px $starsColor, 1199px 895px $starsColor, 8px 1421px $starsColor,
      1734px 710px $starsColor, 356px 1098px $starsColor,
      1488px 1237px $starsColor, 1697px 724px $starsColor,
      1847px 1430px $starsColor, 1327px 1184px $starsColor,
      1838px 879px $starsColor, 1149px 1092px $starsColor,
      1903px 1692px $starsColor, 1251px 1517px $starsColor,
      547px 1933px $starsColor, 690px 1671px $starsColor,
      907px 490px $starsColor, 1822px 738px $starsColor, 757px 988px $starsColor,
      1867px 133px $starsColor, 1292px 1939px $starsColor,
      322px 1093px $starsColor, 1480px 1953px $starsColor,
      1697px 1715px $starsColor, 1420px 958px $starsColor,
      1000px 1164px $starsColor, 844px 895px $starsColor,
      934px 1264px $starsColor;
    animation: animStar 150s linear infinite;
  }
  #stars3:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: 726px 936px $starsColor, 1218px 92px $starsColor,
      1055px 1423px $starsColor, 109px 540px $starsColor,
      1645px 306px $starsColor, 724px 515px $starsColor,
      1637px 1938px $starsColor, 377px 652px $starsColor,
      141px 259px $starsColor, 1949px 71px $starsColor, 1150px 157px $starsColor,
      299px 146px $starsColor, 590px 1453px $starsColor,
      488px 1983px $starsColor, 1229px 1692px $starsColor,
      1270px 282px $starsColor, 1602px 1339px $starsColor,
      178px 1726px $starsColor, 1615px 611px $starsColor,
      916px 1406px $starsColor, 952px 192px $starsColor,
      636px 1290px $starsColor, 304px 1809px $starsColor,
      1472px 1484px $starsColor, 1476px 583px $starsColor,
      1799px 726px $starsColor, 1985px 1650px $starsColor,
      1304px 1009px $starsColor, 1788px 564px $starsColor,
      1631px 1956px $starsColor, 618px 577px $starsColor,
      1419px 1288px $starsColor, 1292px 1498px $starsColor,
      1300px 490px $starsColor, 912px 599px $starsColor,
      1833px 237px $starsColor, 28px 1027px $starsColor,
      1434px 1296px $starsColor, 1812px 250px $starsColor,
      499px 838px $starsColor, 356px 518px $starsColor, 189px 363px $starsColor,
      1039px 1081px $starsColor, 560px 1345px $starsColor,
      1330px 717px $starsColor, 1147px 196px $starsColor,
      739px 822px $starsColor, 99px 1024px $starsColor, 595px 131px $starsColor,
      395px 631px $starsColor, 113px 1541px $starsColor,
      1857px 380px $starsColor, 1322px 1433px $starsColor,
      772px 1685px $starsColor, 1424px 193px $starsColor,
      690px 1405px $starsColor, 11px 1867px $starsColor,
      1171px 225px $starsColor, 404px 407px $starsColor, 650px 193px $starsColor,
      229px 1575px $starsColor, 567px 208px $starsColor,
      1655px 1538px $starsColor, 698px 1739px $starsColor,
      1695px 476px $starsColor, 786px 777px $starsColor, 35px 1005px $starsColor,
      1620px 228px $starsColor, 712px 168px $starsColor,
      1587px 1903px $starsColor, 1241px 850px $starsColor,
      254px 1098px $starsColor, 1850px 888px $starsColor,
      918px 1961px $starsColor, 1199px 895px $starsColor, 8px 1421px $starsColor,
      1734px 710px $starsColor, 356px 1098px $starsColor,
      1488px 1237px $starsColor, 1697px 724px $starsColor,
      1847px 1430px $starsColor, 1327px 1184px $starsColor,
      1838px 879px $starsColor, 1149px 1092px $starsColor,
      1903px 1692px $starsColor, 1251px 1517px $starsColor,
      547px 1933px $starsColor, 690px 1671px $starsColor,
      907px 490px $starsColor, 1822px 738px $starsColor, 757px 988px $starsColor,
      1867px 133px $starsColor, 1292px 1939px $starsColor,
      322px 1093px $starsColor, 1480px 1953px $starsColor,
      1697px 1715px $starsColor, 1420px 958px $starsColor,
      1000px 1164px $starsColor, 844px 895px $starsColor,
      934px 1264px $starsColor;
  }
  .landingText {
    position: absolute;
    width: 100vw;
    height: 100vh;
    @include flexCenter();
    flex-direction: column;
    text-transform: capitalize;
    text-align: center;
    cursor: default;
    .greet {
      font-size: 3rem;
      color: #fff;
      .name {
        color: $main-color-alt;
      }
    }
    .greet-spacing {
      letter-spacing: 2px;
    }
    .button {
      width: 230px;
      max-width: 250px;
      height: 75px;
      position: relative;
      @include flexCenter();
      margin: 2rem auto;
      font-size: 1.4rem;
      z-index: 5;
      border: 1px solid;
      color: #fff;
      text-transform: uppercase;
      transition: 0.5s ease-in-out;
      overflow: hidden;
      cursor: pointer;
    }
    .button:before,
    .button:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: 0.5s ease-in-out;
      z-index: -1;
    }
    .button:before {
      right: -50px;
      border-right: 50px solid transparent;
      border-bottom: 80px solid $main-color-alt;
      transform: translateX(-100%);
    }
    .button:after {
      left: -50px;
      border-left: 50px solid transparent;
      border-top: 80px solid $main-color-alt;
      transform: translateX(100%);
    }
    .button:hover {
      color: $paragraph-color-alt;
    }
    .button:hover:before {
      transform: translateX(-49%);
    }
    .button:hover:after {
      transform: translateX(49%);
    }
  }
  .glass {
    width: 225px;
    height: 50px;
    background: $glass-color;
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    position: relative;
    top: 80px;
    left: -25px;
    text-transform: capitalize;
    @include flexBox(center, center);
    color: #fff;
    padding-left: 10px;
    #spring,
    #summer,
    #autumn,
    #winter {
      position: absolute;
      max-width: 50px;
      left: 200px;
      top: 50%;
      transform: translateY(-50%);
      transition: transform 0.3s ease-in-out;
      &:hover {
        transform: translateY(-50%) rotate(10deg);
      }
    }
    #winter {
      max-width: 40px; // Snowflake should be slightly smaller
    }
  }
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}

@media (max-width: 769px) {
  .body {
    .landingText {
      .greet {
        font-size: 2rem;
      }
    }
  }
}

@media (max-width: 426px) {
  .body {
    .landingText {
      .greet {
        font-size: 1.5rem;
      }
      .button {
        width: 200px;
        max-width: 220px;
        height: 65px;
        font-size: 1.2rem;
      }
    }
  }
}
