@use "../../index.scss" as *;

.header {
  font-family: "Cairo", sans-serif;
  background-color: $header-background;
  text-transform: capitalize;
  color: #fff;
  position: sticky;
  top: 0px;
  z-index: 4444;
  width: 100vw;
  overflow: hidden;
  transition: opacity $main-transition;
  .container {
    .mainNav {
      width: 100%;
      @include flexBox(space-between, center);
      .navOne {
        width: 15vw;
        @include flexBox(space-around, center);
        li {
          padding: 15px 13px;
          transition: $main-transition;
          cursor: pointer;
          a {
            transition: $main-transition;
          }
          .active {
            color: $main-color-alt;
          }
        }
        li:hover {
          color: $main-color-alt;
        }
      }
      .animated-button {
        background: $main-gradient;
        color: #fff;
        padding: 10px 20px;
        margin: 8px;
        display: inline-block;
        transform: translate(0%, 0%);
        overflow: hidden;
        letter-spacing: 2.5px;
        text-align: center;
        text-transform: uppercase;
        box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
        transition: $main-transition;
        cursor: pointer;
        li {
          position: relative;
          z-index: 10;
        }
      }
      .animated-button::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: $main-color-alt;
        opacity: 0;
        transition: $main-transition opacity ease-in-out;
      }
      .animated-button:hover::before {
        opacity: 0.1;
      }
      .animated-button:hover {
        color: $main-color-alt;
      }
      .animated-button span {
        position: absolute;
      }
      .animated-button span:nth-child(1) {
        top: 0px;
        left: 0px;
        width: 100%;
        height: 2px;
        background: linear-gradient(to left, rgba(8, 20, 43, 0), $alt-color);
        animation: 2s animateTop linear infinite;
      }
      .animated-button span:nth-child(2) {
        top: 0px;
        right: 0px;
        height: 100%;
        width: 2px;
        background: linear-gradient(to top, rgba(8, 20, 43, 0), $alt-color);
        animation: 2s animateRight linear -1s infinite;
      }
      .animated-button span:nth-child(3) {
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 2px;
        background: linear-gradient(to right, rgba(8, 20, 43, 0), $alt-color);
        animation: 2s animateBottom linear infinite;
      }
      .animated-button span:nth-child(4) {
        top: 0px;
        left: 0px;
        height: 100%;
        width: 2px;
        background: linear-gradient(to bottom, rgba(8, 20, 43, 0), $alt-color);
        animation: 2s animateLeft linear -1s infinite;
      }
    }
  }
}

@keyframes animateTop {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes animateRight {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes animateBottom {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes animateLeft {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}

@keyframes hideAndShow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 350px) {
  .header {
    .container {
      .mainNav {
        font-size: 0.8rem;
        .navOne {
          width: 25vw;
        }
      }
    }
  }
}

@media (max-width: 521px) {
  .header {
    .container {
      .mainNav {
        @include flexCenter();
        flex-direction: column;
        .navOne {
          width: 40vw;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .header {
    .container {
      .mainNav {
        .navOne {
          width: 60vw;
        }
      }
    }
  }
}
