@use "../../index.scss" as *;

.aboutSection {
  font-family: "Cairo", sans-serif;
  padding: 70px 0 50px;
  width: 100vw;
  background-color: $main-color;
  position: relative;
  z-index: 5;
  overflow: hidden;
  .container {
    .box {
      // border: 1px solid red;
      width: 100%;
      @include flexBox(space-between, center);
      .img {
        // border: 1px solid red;
        width: 45%;
        img {
          max-width: 100%;
        }
      }
      .about {
        // border: 1px solid red;
        width: 55%;
        padding: 25px;
        .mainTitle {
          width: fit-content;
          text-transform: uppercase;
          color: $alt-color;
          position: relative;
          font-size: 0.7rem;
          letter-spacing: 1px;
        }
        .mainTitle::after {
          content: "";
          width: 50%;
          height: 5px;
          position: absolute;
          border-radius: 5px;
          background-color: $alt-color;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0px;
        }
        .jobTitle {
          text-transform: capitalize;
          color: $paragraph-color-alt;
          margin: 15px 0;
          font-size: 1.5rem;
        }
        .bio {
          color: $paragraph-color;
          line-height: 2;
          word-spacing: 1.5px;
          letter-spacing: 1px;
          p {
            margin: 15px 0;
          }
        }
        .services {
          @include flexBox(space-between, center);
          margin: 30px 0;
          padding: 10px 0;
          .icon {
            @include flexCenter();
            flex-direction: column;
            text-transform: uppercase;
            width: fit-content;
            color: $paragraph-color-alt;
            font-size: 2.5rem;
            .title {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1439px) {
  .aboutSection {
    .container {
      .box {
        .img {
          width: 40%;
        }
        .about {
          width: 60%;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .aboutSection {
    .container {
      .box {
        flex-direction: column;
        .img {
          width: 30%;
        }
        .about {
          width: 100%;
          .services {
            flex-wrap: wrap;
            @include flexCenter();
            .icon {
              margin: 10px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 485px) {
  .aboutSection {
    text-align: center;
    padding-top: 100px;
    .container {
      .box {
        .img {
          display: none;
        }
        .about {
          width: 100%;
          .mainTitle {
            left: 50%;
            transform: translateX(-50%);
          }
          .jobTitle {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
