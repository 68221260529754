@use "../../index.scss" as *;

.break {
  font-family: "Cairo", sans-serif;
  width: 100%;
  height: 25vh;
  background: $main-color;
  position: relative;
  z-index: 5;
  .content {
    background: rgba(255, 255, 255, 0.1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include flexBox(center, center);
    flex-direction: column;
    .breakTitle {
      text-transform: uppercase;
      color: $alt-color;
      position: relative;
      font-size: 2rem;
      letter-spacing: 1px;
      margin: 0 0 20px;
    }
    .breakTitle::after {
      content: "";
      width: 50%;
      height: 5px;
      position: absolute;
      border-radius: 5px;
      background-color: $alt-color;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0px;
    }
    .breakDescription {
      color: $paragraph-color;
      line-height: 2;
      word-spacing: 1.5px;
      letter-spacing: 1px;
    }
  }
}

@media (max-width: 485px) {
  .break {
    .content {
      padding: 10px 20px;
      .breakDescription {
        text-align: center;
      }
    }
  }
}
