@use "../../index.scss" as *;

.clock {
  color: $paragraph-color-alt;
  text-align: center;
  .title {
    text-transform: capitalize;
    margin: 0 0 10px;
    span {
      color: $main-color-alt;
    }
  }
  .timedate {
    color: $paragraph-color;
    font-size: 18px;
    margin: 10px 0;
  }
}
