@import '../../index.scss';

.badge {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  z-index: 1;
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  animation: badgePulse 2s infinite;
  &.featured {
    background-color: rgba($main-color-alt, 0.9);
    color: white;
  }
  &.new {
    background-color: rgba($alt-color, 0.9);
    color: white;
  }
  &.training {
    background-color: rgba(#4CAF50, 0.9);
    color: white;
  }
}

@keyframes badgePulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
