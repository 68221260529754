@use "../../index.scss" as *;

.footer {
  font-family: "Raleway", sans-serif;
  width: 100vw;
  position: relative;
  z-index: 1;
  padding: 50px 0;
  background-color: $alt-color2;
  overflow: hidden;
  .container {
    .box {
      @include flexBox(space-between, center);
      position: relative;
      .contactInfo {
        width: 30%;
        .logo {
          margin: 0 auto;
          text-transform: capitalize;
          color: $paragraph-color-alt;
          text-align: center;
          span {
            color: $main-color-alt;
          }
        }
        .contactIcons {
          width: fit-content;
          @include flexCenter();
          margin: 20px auto;
          .icon {
            margin: 0 10px;
            padding: 2px;
            font-size: 25px;
            color: $paragraph-color;
            transition: $main-transition;
            cursor: pointer;
          }
          .linkedIn:hover {
            color: #0077b5;
          }
          .gitHub:hover {
            color: #6e5494;
          }
          .frontendMentor:hover {
            color: #3f54a3;
          }
          .freeCodeCamp:hover {
            color: #0a0a23;
          }
        }
      }
      .workInfo {
        width: 30%;
        color: $paragraph-color-alt;
        text-align: center;
        .title {
          text-transform: capitalize;
          margin: 0 0 10px;
          span {
            color: $main-color-alt;
          }
        }
        .workingtime {
          margin: 10px 0;
          color: $paragraph-color;
        }
      }
      .timeDateEG {
        width: 30%;
      }
    }
  }
}

@media (max-width: 665px) {
  .footer {
    .container {
      .box {
        @include flexCenter();
        flex-direction: column;
        .contactInfo {
          width: 80%;
          margin: 25px 0;
        }
        .workInfo {
          width: 80%;
          margin: 25px 0;
        }
        .timeDateEG {
          width: 80%;
          margin: 25px 0;
        }
      }
    }
  }
}
