@use "../../index.scss" as *;

.contactForm {
  font-family: "Cairo", sans-serif;
  width: 100vw;
  position: relative;
  z-index: 5;
  padding: 100px 0;
  background-color: $main-color;
  overflow: hidden;
  .container {
    .box {
      width: 100%;
      @include flexBox(space-between, center);
      .text {
        width: 50%;
        .mainTitle {
          width: fit-content;
          text-transform: uppercase;
          color: $alt-color;
          position: relative;
          font-size: 0.7rem;
          letter-spacing: 1px;
        }
        .mainTitle::after {
          content: "";
          width: 50%;
          height: 5px;
          position: absolute;
          border-radius: 5px;
          background-color: $alt-color;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0px;
        }
        .quote {
          text-transform: capitalize;
          color: $paragraph-color-alt;
          margin: 15px 0;
          font-size: 1.4rem;
        }
        .description {
          color: $paragraph-color;
          line-height: 2;
          word-spacing: 1.5px;
          letter-spacing: 1px;
          // text-transform: capitalize;
          .problem {
            width: fit-content;
            @include flexCenter();
            .icon {
              margin: 7px 5px 0;
            }
            .emailIcon {
              cursor: pointer;
              transition: $main-transition;
            }
            .emailIcon:hover {
              color: red;
            }
          }
        }
      }
      .form {
        width: 45%;
        padding: 15px;
        .field {
          @include flexCenter();
          position: relative;
          margin: 20px;
          .icon {
            font-size: 25px;
            position: relative;
            right: 10px;
            top: 5px;
            color: $alt-color2;
            transition: $main-transition;
          }
          .input {
            width: 80%;
            background-color: $main-color !important;
            border: 2px solid $alt-color2;
            outline: none;
            caret-color: $alt-color;
            padding-left: 8px;
            color: $alt-color;
            padding: 15px 25px;
            font-size: 20px;
            transition: $main-transition;
          }
          .label {
            color: $paragraph-color-alt;
            text-transform: capitalize;
            position: absolute;
            top: 50%;
            left: 75px;
            transform: translateY(-50%);
            transition: $main-transition;
            font-size: 20px;
            pointer-events: none;
          }
          .input:focus + .label,
          .input:valid + .label {
            font-size: 15px;
            top: 0;
            background-color: $main-color;
            padding: 5px;
          }
          .message {
            height: 200px;
          }
          .messageLabel {
            top: 25px;
          }
          .messageIcon {
            top: -70px;
          }
        }
        .field:focus-within .icon {
          color: $alt-color;
        }
        .field:focus-within .label {
          color: $alt-color;
        }
        .field:focus-within .input {
          border-color: $alt-color;
        }
        .button {
          border: 2px solid $alt-color2;
          width: fit-content;
          padding: 10px 35px;
          color: $paragraph-color-alt;
          text-transform: capitalize;
          font-size: 20px;
          cursor: pointer;
          transition: $main-transition;
          background-color: transparent;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
        }
        .button:hover {
          border-color: $alt-color;
          color: $alt-color;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .contactForm {
    .container {
      .box {
        .form {
          width: 50%;
          .field {
            .input {
              width: 90%;
            }
            .label {
              left: 50px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .contactForm {
    .container {
      .box {
        @include flexCenter();
        flex-direction: column;
        .text {
          width: 80%;
        }
        .form {
          width: 80%;
          padding: 10px;
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .contactForm {
    .container {
      .box {
        @include flexCenter();
        flex-direction: column;
        .text {
          width: 90%;
          text-align: center;
          .mainTitle {
            margin: 0 auto;
          }
          .quote {
            font-size: 1.2rem;
          }
          .description {
            .problem {
              margin: 0 auto;
            }
          }
        }
        .form {
          width: 100%;
          .field {
            .input {
              width: 90%;
            }
            .label {
              left: 50px;
            }
          }
        }
      }
    }
  }
}
