@use "../../index.scss" as *;

.overlay {
  width: 100vw;
  height: 100vh;
}

.backToToputton {
  position: fixed;
  color: $paragraph-color;
  z-index: 5000;
  bottom: -50px;
  right: -50px;
  font-size: 2rem;
  cursor: pointer;
  transition: $main-transition;
}

.backToToputton:hover {
  color: $paragraph-color-alt;
}

.showUp {
  bottom: 15px;
  right: 20px;
}
